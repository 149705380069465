import "./App.css";
import { useEffect, useState } from "react";
import purposebiglogo from "./images/purposebiglogo.png";
import signature from "./images/signature.png";
import signature2 from "./images/signature2.png";
import signature3 from "./images/signature3.png";
import amal from "./images/amal.png";
import rasmin from "./images/rasmin.png";
import krishnendu from "./images/krishnendu.png";
import shahabanath from "./images/shahabanath.png";
import seal from "./images/seal.png";
import { usePapaParse } from "react-papaparse";
import Certificate2 from "./Certificate2";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";

//Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    alignItems: "center",
  },
  section: {
    margin: 30,
    padding: 10,
    //flexGrow: 1,
  },
  certificateOfCompletion: {
    marginTop: 60,
    fontSize: 32,
    fontWeight: 900,
  },
  name: {},
  text: {},
  certificateId: {},
  bottomSection: {},
  signatureLeft: {},
  signatureRight: {},
  signature: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 500,
  },
  signatureDesignation: {
    fontSize: 14,
  },
});

// Create Certificate Component
const Certificate = ({ certificate, leftSign }) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View>
        <Image
          style={{
            marginTop: 42,
            height: 50,
            fontSize: 32,
            fontWeight: "bold",
          }}
          src={purposebiglogo}
        />
      </View>
      <View style={{ marginTop: 15, fontSize: 32, fontWeight: "bold" }}>
        <Text>
          {certificate.title ? certificate.title : "CERTIFICATE OF COMPLETION"}
        </Text>
      </View>
      <View
        style={{
          marginTop: 40,
          fontSize: 22,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 15 }}>This certifies that</Text>
        <Text>{certificate.name}</Text>
      </View>
      <View style={styles.text}>
        <Text
          style={{
            fontSize: 18,
            marginTop: 15,
            marginBottom: 15,
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          {certificate.text}
        </Text>
      </View>
      <View style={{ fontSize: 18, margin: 15 }}>
        <Text>Certificate ID: {certificate.id}</Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "space-between",
          marginTop: 30,
          width: 760,
        }}
      >
        <View style={styles.signature}>
          <Image
            style={{
              height: 50,
              fontSize: 32,
              fontWeight: "bold",
            }}
            src={leftSign}
          />
          <Text>
            {certificate.leftName ? certificate.leftName : "Amal Sivan"}
          </Text>
          <Text style={styles.signatureDesignation}>
            {certificate.leftName
              ? certificate.leftDesignation
              : "Project Manager"}
          </Text>
          <Text style={styles.signatureDesignation}>
            Purpose Technology LLP
          </Text>
        </View>
        <View sytle={styles.signature}>
          <Image
            style={{
              height: 100,
              width: 110,
              fontSize: 32,
              fontWeight: "bold",
            }}
            src={seal}
          />
        </View>
        <View style={styles.signature}>
          <Image
            style={{
              height: 50,
              fontSize: 32,
              fontWeight: "bold",
            }}
            src={signature}
          />
          <Text>Mr. Radhakrishnan A D</Text>
          <Text style={styles.signatureDesignation}>
            Chief Executive Officer
          </Text>
          <Text style={styles.signatureDesignation}>
            Purpose Technology LLP
          </Text>
        </View>
      </View>
      <View>
        <Text style={{ fontSize: 9, margin: 30 }}>
          Verify at{" "}
          <a href="https://certificates.purposetechnology.in">
            https://certificates.purposetechnology.in
          </a>
        </Text>
      </View>
    </Page>
  </Document>
);

// Create Certificate Component

function App() {
  const [certificateId, setCertificateId] = useState("");
  const [hasFoundCert, setHasFoundCert] = useState(false);
  const [certificate, setCertificate] = useState({});
  const [leftSign, setLeftSign] = useState(amal);
  const [certData, setCertData] = useState([]);
  const { readRemoteFile } = usePapaParse();

  useEffect(() => {
    readRemoteFile(process.env.REACT_APP_GOOGLE_SHEETS_URL, {
      complete: (results) => {
        //console.log("---------------------------");
        //console.log("Results:", results);
        //console.log("---------------------------");
        var data = results.data;

        //convert csv array data to json
        let jsonData = [];
        for (let i = 1; i < data.length; i++) {
          let obj = {};
          for (let j = 0; j < data[i].length; j++) {
            if (data[i][j]) {
              obj = { ...obj, [data[0][j]]: `${data[i][j]}` };
            }
          }
          jsonData.push(obj);
        }
        setCertData(jsonData);
      },
    });
  }, []);
  const handleChange = (e) => {
    setCertificateId(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("test");

    fetch(
      //`http://localhost:3000/api/certificate?certificateId=${certificateId}`
      `https://certificate-next-api.vercel.app/api/certificate?certificateId=${certificateId}`
    )
      .then((response) => response.json())
      .then((jsonData) => {
        console.log({ jsonData });
        if (jsonData.success === true) {
          setHasFoundCert(true);
          setCertificate(jsonData.data);
          if (
            jsonData.data.leftName &&
            jsonData.data.leftName.includes("Krishnendu")
          ) {
            setLeftSign(krishnendu);
          } else if (
            jsonData.data.leftName &&
            jsonData.data.leftName.toLowerCase().includes("shahabanath")
          ) {
            setLeftSign(shahabanath);
          } else if (
            jsonData.data.leftName &&
            jsonData.data.leftName.includes("Rasmin")
          ) {
            setLeftSign(rasmin);
          }
        } else {
          alert(jsonData.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="App">
      {hasFoundCert ? (
        <div
          style={{
            width: "100%",
          }}
        >
          <PDFViewer width="100%" style={{ height: window.innerHeight }}>
            <Certificate2
              certificate={certificate}
              leftSign={leftSign}
            ></Certificate2>
          </PDFViewer>{" "}
        </div>
      ) : (
        <div>
          <br />
          <h1>Verify Certificate</h1>
          <p>
            Verify credibility of all certificates issued by Purpose Technology
            LLP, North Paravur, Kochi.
          </p>
          <form onSubmit={handleSubmit}>
            Certificate ID{" "}
            <input
              type="text"
              value={certificateId}
              name="certificateId"
              onChange={handleChange}
            />
            <button type="submit">Verify</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default App;
