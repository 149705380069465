import { useEffect, useState } from "react";
import purposebiglogo from "./images/purposebiglogo.png";
import signature from "./images/signature.png";
import signature2 from "./images/signature2.png";
import signature3 from "./images/signature3.png";
import amal from "./images/amal.png";
import krishnendu from "./images/krishnendu.png";
import shahabanath from "./images/shahabanath.png";
import seal from "./images/seal.png";
import borderPng from "./images/border-art.png";
//import borderSvg from "./images/border-art.svg";
import { usePapaParse } from "react-papaparse";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";

//Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    alignItems: "center",
    justifyContent: "center",
  },
  backgroundImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    opacity: 1, // Adjust the opacity as needed
  },
  fullCertificate: {
    border: "10px solid #333",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
    margin: 30,
    padding: 10,
    alignItems: "center", // This centers content horizontally
    justifyContent: "space-evenly", // This centers content vertically
    flexDirection: "column",
  },
  section: {
    margin: 30,
    //padding: 10,
    //flexGrow: 1,
  },
  certificateOfCompletion: {
    //marginTop: 60,
    fontSize: 32,
    fontWeight: 900,
  },
  name: {},
  text: {},
  certificateId: {},
  bottomSection: {},
  signatureLeft: {},
  signatureRight: {},
  signature: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 500,
  },
  signatureDesignation: {
    fontSize: 14,
  },
});
const Certificate2 = ({ certificate, leftSign }) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      {/* Background Image */}
      {/*<Image src={borderPng} style={styles.backgroundImage} />*/}
      <View style={styles.fullCertificate}>
        <View>
          <Image
            style={{
              marginTop: 5,
              height: 50,
              fontSize: 32,
              fontWeight: "bold",
            }}
            src={purposebiglogo}
          />
        </View>
        <View style={{ marginTop: 15, fontSize: 32, fontWeight: "bold" }}>
          <Text>
            {certificate.title
              ? certificate.title
              : "CERTIFICATE OF COMPLETION"}
          </Text>
        </View>
        <View
          style={{
            marginTop: 40,
            fontSize: 22,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 15 }}>This certifies that</Text>
          <Text>{certificate.name}</Text>
        </View>
        <View style={styles.text}>
          <Text
            style={{
              fontSize: 16,
              marginTop: 15,
              marginBottom: 15,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            {certificate.text}
          </Text>
        </View>
        <View style={{ fontSize: 18, margin: 15 }}>
          <Text>Certificate ID: {certificate.id}</Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "space-between",
            marginTop: 30,
            width: 760,
          }}
        >
          <View style={styles.signature}>
            <Image
              style={{
                height: 50,
                fontSize: 32,
                fontWeight: "bold",
              }}
              src={leftSign}
            />
            <Text>
              {certificate.leftName ? certificate.leftName : "Amal Sivan"}
            </Text>
            <Text style={styles.signatureDesignation}>
              {certificate.leftName
                ? certificate.leftDesignation
                : "Project Manager"}
            </Text>
            <Text style={styles.signatureDesignation}>
              Purpose Technology LLP
            </Text>
          </View>
          <View sytle={styles.signature}>
            <Image
              style={{
                height: 100,
                width: 110,
                fontSize: 32,
                fontWeight: "bold",
              }}
              src={seal}
            />
          </View>
          <View style={styles.signature}>
            <Image
              style={{
                height: 50,
                fontSize: 32,
                fontWeight: "bold",
              }}
              src={signature}
            />
            <Text>Mr. Radhakrishnan A D</Text>
            <Text style={styles.signatureDesignation}>
              Chief Executive Officer
            </Text>
            <Text style={styles.signatureDesignation}>
              Purpose Technology LLP
            </Text>
          </View>
        </View>
        <View>
          <Text style={{ fontSize: 9, margin: 30 }}>
            Verify at{" "}
            <a href="https://certificates.purposetechnology.in">
              https://certificates.purposetechnology.in
            </a>
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default Certificate2;
